import Vue from 'vue';
import Vuex from 'vuex';

import accessToken from './modules/accessToken';
import auth from './modules/auth';
import users from './modules/users';
import empresa from './modules/empresa';
import imagem from './modules/imagem';
import coordenadasmarcador from './modules/coordenadasmarcador';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('accessToken/verifica');
    },
  },

  modules: {
    accessToken,
    auth,
    users,
    empresa,
    imagem,
    coordenadasmarcador,
  },
});
