import apiempresaweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
    allowed: null,
  },

  getters: {
    list: (state) => state.list,
    allowed: (state) => state.allowed ?? false,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async verifica({ commit }) {
      const storageAuth = sessionStorage.getItem('auth:token');
      let existeValidacao = null;
      const urlParams = new URLSearchParams(window.location.search);
      let lat = urlParams.get('lat');
      let lng = urlParams.get('lng');
      if ((!lat) || (lat === '')) {
        lat = -21.929010244676626;
      }
      if ((!lng) || (lng === '')) {
        lng = -50.515552937016686;
      }

      sessionStorage.setItem('startAt', JSON.stringify({ lat, lng }));
      const key = urlParams.get('key');
      if (key) {
        try {
          urlParams.delete('key');
          sessionStorage.setItem('auth:token', JSON.stringify({
            expires: null,
            token: key,
            userId: null,
          }));
          const { data } = await apiempresaweb.get('accesstoken/validachave', { params: { key } });
          existeValidacao = !!data;
          sessionStorage.setItem('auth:token', JSON.stringify(data));
        } catch (e) {
          sessionStorage.clear();
          if (e.response?.status === 401) {
            console.log('%c:: NOT-AUTHORIZED ::', 'color:red;border:1px solid black');
          } else {
            console.log('%c:: ERROR-ON-QUERY-REQUEST ::', 'color:red;border:1px solid black');
          }
        }
      } else if (storageAuth) {
        try {
          const { data } = await apiempresaweb.get('accesstoken/validachave', { params: { key } });
          existeValidacao = !!data;
          sessionStorage.setItem('auth:token', JSON.stringify(data));
        } catch (e) {
          console.log('%c:: ERROR-ON-STORAGE-REQUEST ::', 'color:red;border:1px solid black');
          sessionStorage.clear();
        }
      } else {
        console.log('%c:: NO-KEY ::', 'color:red;border:1px solid black');
      }

      if (existeValidacao) {
        console.log('%c:: ALLOWED ::', 'color:red;border:1px solid black');
        commit('SET_ALLOWED', true);
      } else {
        console.log('%c:: NOT-ALLOWED ::', 'color:red;border:1px solid black');
        commit('SET_ALLOWED', false);
      }
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },

    SET_ALLOWED(state, bool) {
      state.allowed = bool ?? false;
    },
  },
};
