import apiempresaweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async create(_, body) {
      try {
        const [{ data }] = await Promise.all([
          apiempresaweb.post('coordenadasmarcador/maisrecente', body),
        ]);
        return data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
